import * as React from 'react';
import { Helmet } from 'react-helmet';
import Container from '@mui/material/Container';
import { styled } from '@mui/material/styles';
import MainContent from 'components/MainContent/MainContent';
import newsBg from 'images/news/news-background.png';

const Header = styled('div')`
  background: url(${newsBg});
  position: relative;
  left: 0;
  right: 0;
  height: 9.0625rem;
`;

const HeaderTextContainer = styled('div')`
  display: flex;
  text-align: center;
  justify-content: center;
  color: white;
  height: 5.625rem;
`;

const StyledHeader = styled('h2')`
  font-size: 2.5rem;
`;

const StyledText = styled('p')`
  font-family: 'Lato', sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.5rem;
  text-decoration-line: underline;
  margin-bottom: 3rem;
`;

const StyledLink = styled('a')`
  color: black; ;
`;

const HeaderText = styled('h2')`
  margin: 3.5rem;
`;

const ArticlesPage = () => {
  // @ts-ignore
  return (
    <MainContent>
      <Helmet>
        <title>Articles</title>
        <link rel='canonical' href={`${process.env.GATSBY_SITE_URL}/articles/`} />
      </Helmet>
      <Header>
        <HeaderTextContainer>
          <HeaderText>News & Blog</HeaderText>
        </HeaderTextContainer>
      </Header>
      <Container>
        <StyledHeader>Featured Articles</StyledHeader>
        <StyledText>
          <StyledLink href='https://www.builtinchicago.org/chicago-top-funding-rounds-april-2022' target='_blank'>
            These 5 Chicago Tech Companies Raised April’s Largest Funding Rounds
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink
            href='https://www.builtinchicago.org/2022/07/29/chicago-tech-companies-hiring-july-2022'
            target='_blank'
          >
            These Chicago Companies Are Hiring Now
          </StyledLink>
        </StyledText>
        <StyledHeader>Press Releases</StyledHeader>
        <StyledText>
          <StyledLink
            href='https://www.businesswire.com/news/home/20220419005059/en/Entertainment-Tech-Start-Up-Live-Bash-Inc.-Debuts-With-21-Million-Capital-Raise-From-Oversubscribed-Initial-Funding-Round'
            target='_blank'
          >
            Entertainment Tech Start Up Live Bash Inc. Debuts With $21 Million Capital Raise From Oversubscribed Initial
            Funding Round
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink
            href='https://www.zdnet.com/finance/blockchain/entertainment-tech-startup-live-bash-raises-21m-in-seed-funding-to-give-performers-the-chance-to-step-up-to-the-mic/'
            target='_blank'
          >
            Live Bash raises $21M, plans to revolutionize performance industry with blockchain tech
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink
            href='https://www.yahoo.com/now/entertainment-tech-start-live-bash-123000133.html'
            target='_blank'
          >
            Entertainment Tech Start Up Live Bash Inc. Debuts With $21 Million Capital Raise From Oversubscribed Initial
            Funding Round (Yahoo!)
          </StyledLink>
        </StyledText>
        <StyledHeader>Blog Posts</StyledHeader>
        <StyledText>
          <StyledLink href='https://medium.com/@jonny.levin/this-is-content-9426e77f5d27' target='_blank'>
            This Is Content
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink
            href='https://medium.com/@cole.sosnoff/why-launching-a-start-up-with-people-in-5-different-time-zones-was-our-savviest-move-c231e954f20'
            target='_blank'
          >
            Why Launching A Start-Up With People In 5 Different Time Zones Was Our Savviest Move
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink href='https://medium.com/@eduardo.rihan/emojional-intelligence-4e727c72f22a' target='_blank'>
            Emojinal Intelligence
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink
            href='https://medium.com/@jonny.levin/can-one-be-funny-when-stuck-in-a-room-ed37e67e796e'
            target='_blank'
          >
            Can One Be Funny When Stuck in a Room?
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink href='https://medium.com/@eduardo.rihan/pain-et-vie-282b0e803c02' target='_blank'>
            Giving and Getting Bread
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink
            href='https://medium.com/@cole.sosnoff/whats-luck-gotta-do-with-it-my-journey-into-poker-crypto-and-nfts-ee4c1d10b250'
            target='_blank'
          >
            What’s Luck Gotta Do With It? My Journey into Poker, Crypto, and NFTs
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink href='https://medium.com/@john_53912/the-stage-is-set-4eae07683217' target='_blank'>
            The Stage is Set
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink href='https://medium.com/@cole.sosnoff/talk-ducky-to-me-e7fb8e2a9091' target='_blank'>
            Talk Ducky To Me
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink
            href='https://medium.com/@jonny.levin/the-case-for-doing-it-live-edited-90ca2b36a397'
            target='_blank'
          >
            The Case For Doing It Live (edited)
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink
            href='https://medium.com/@john_53912/dont-love-your-idea-to-death-pacing-the-scaling-journey-907392387c09'
            target='_blank'
          >
            Don&quot;t Love To Death
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink href='https://medium.com/@bree.pear/not-your-average-show-merch-980428f8b050' target='_blank'>
            Not Your Average Show Merch
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink href='https://medium.com/@bree.pear/the-web3-we-weave-72d42813c428' target='_blank'>
            The Web3 We Weave
          </StyledLink>
        </StyledText>
        <StyledText>
          <StyledLink href='https://medium.com/@eduardo.rihan/3-feet-from-gold-c52f0cc7b9e4' target='_blank'>
            3 Feet From Gold
          </StyledLink>
        </StyledText>
      </Container>
    </MainContent>
  );
};

export default ArticlesPage;
